<template>
  <main class="container pb-16 pb-lg-32 px-lg-32">
    <div class="mb-8" v-if="schedule">
      <ScheduleInfo :schedule="schedule" />
    </div>

    <MaterialForm :schedule="schedule" />
  </main>
</template>

<script>
import Schedule from '../../../../store/models/Schedule';

import ScheduleInfo from '../../../../components/student/schedule/ScheduleInfo.vue';
import MaterialForm from '../../../../components/teacher/material/MaterialForm.vue';

export default {
  components: { ScheduleInfo, MaterialForm },

  computed: {
    schedule() {
      return Schedule.query()
        .withAllRecursive()
        .find(this.$route.params.schedule_id);
    },
  },

  metaInfo() {
    return {
      title: 'Tambah',
    };
  },

  async beforeMount() {
    if (!this.schedule) {
      await this.$store.dispatch('schedules/maybeFetch', {
        filter: { id: this.$route.params.schedule_id },
      });
    }

    this.$store.commit('info/setBreadcrumb', [
      { path: '/guru/jadwal', label: 'Jadwal' },
      {
        path: this.schedule.scheduleRoutePath,
        label: this.schedule.subject.name,
      },
      { path: this.schedule.materialsRoutePath, label: 'Materi' },
    ]);
  },
};
</script>
