<template>
  <form class="bg-white rounded-8" @submit.prevent="handleSubmit">
    <div class="d-flex align-items-center justify px-24 py-16 border-bottom">
      <h2 class="fs-20 me-auto mb-0">Materi</h2>
      <button
        type="submit"
        class="btn btn-primary"
        :class="{ 'is-loading': isSubmitting }"
        :disabled="isSubmitting"
      >
        Simpan
      </button>
    </div>

    <div class="p-24">
      <InputField
        label="Judul"
        required
        v-model="input.title"
        :error="errors.title"
      />
      <RichTextField
        class="mt-16"
        label="Deskripsi"
        v-model="input.content"
        :error="errors.content"
      />
      <FileField
        class="mt-16"
        label="File"
        v-model="input.file"
        :error="errors.file"
      />
    </div>
  </form>
</template>

<script>
import get from 'lodash/get';
import pick from 'lodash/pick';
import materialRepository from '../../../api/repositories/materialRepository';

import FileField from '../../form/FileField.vue';
import InputField from '../../form/InputField.vue';
import RichTextField from '../../form/RichTextField.vue';

export default {
  components: { FileField, InputField, RichTextField },

  props: ['schedule', 'material'],

  data() {
    return { errors: {}, input: this.getInitialInput(), isSubmitting: false };
  },

  methods: {
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },

    async handleSubmit() {
      try {
        this.errors = {};
        this.isSubmitting = true;
        const input = this.prepareInput();

        if (this.input.id) {
          await materialRepository.update(input);
          this.$alert.success('Materi berhasil diubah');
        } else {
          await materialRepository.store(input);
          this.$alert.success('Materi berhasil dibuat', {
            onOK: () =>
              this.$router.push(`/guru/jadwal/${this.schedule.id}/materi`),
          });
        }

        this.$store.commit('materials/invalidate');
      } catch (error) {
        this.errors = get(error, 'response.data.error.errors', {});
        this.$alert.requestError(error);
      } finally {
        this.isSubmitting = false;
      }
    },

    getInitialInput() {
      const input = this.material ? this.material.$toJson() : {};
      input.due_time =
        input.due_time && this.material.dueTimeJS.format('YYYY-MM-DD HH:mm');
      return input;
    },

    prepareInput() {
      return {
        ...this.input,
        ...pick(this.schedule, ['grade_id', 'subject_id']),
      };
    },
  },

  watch: {
    material() {
      this.input = this.getInitialInput();
    },
  },
};
</script>
