<template>
  <div class="row gx-16">
    <div class="col-6" v-for="info of infos" :key="info.desc">
      <div class="d-sm-flex align-items-center bg-white p-16 rounded-8 mb-16">
        <component
          class="fs-28 text-primary me-16 mb-8 mb-sm-0"
          :is="info.icon"
        />
        <div>
          <h3 class="fs-20 mb-0" v-html="info.content"></h3>
          <div class="text-muted">{{ info.desc }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClockIcon from 'bootstrap-icons/icons/clock.svg';
import Easel2Icon from 'bootstrap-icons/icons/easel2.svg';
import JournalsIcon from 'bootstrap-icons/icons/journals.svg';
import PersonWorkspaceIcon from 'bootstrap-icons/icons/person-workspace.svg';

export default {
  props: ['schedule'],

  computed: {
    infos() {
      return [
        {
          icon: JournalsIcon,
          content: this.schedule.subject.name,
          desc: 'Pelajaran',
        },
        {
          icon: PersonWorkspaceIcon,
          content: this.schedule.teacher.user.name,
          desc: 'Guru',
        },
        {
          icon: ClockIcon,
          content: this.schedule.timeText,
          desc: 'Jam',
        },
        {
          icon: Easel2Icon,
          content: this.schedule.grade.name,
          desc: 'Kelas',
        },
      ];
    },
  },
};
</script>
